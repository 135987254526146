import axios from "axios";
// console.log(process.env.NODE_ENV)
// let url = process.env.NODE_ENV == "development" ? 'https://jqadmin.tangshannanhu.com/api' : 'https://jqadmin.tangshannanhu.com/api'
const server = axios.create({
    baseURL: 'https://jqadmin.tangshannanhu.com/api',
    // baseURL: url,
    timeout: 6000
})

server.interceptors.response.use(
    (res) => {
        if(res.status == 200){
            return res.data
        }
    },
    (err) => {
        return Promise.reject(err)
    }
);

export default server;