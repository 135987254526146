<template>
  <div class="container">
      <!-- 切换南湖、开滦 -->
      <div class="map-top fx fa fb">
          <div
          v-for="(item,index) in quyus"
          :key="index"
          :class="topIndex == item.id ? 'top-btn btn-active' : 'top-btn'"
          @click.stop="mapTopClick(item)">
          {{item.name}}
          </div>
      </div>
      <!-- 地图区域 -->
      <div class="map-container">
        <Map
            :centerLatLng="centerLatLng"
            :centerLatLng2="centerLatLng2"
            @markerClick="markerClick"
            :markers="markers"
            :latLng="lines"
            :points="points"
            :markerIcon="activeGridIndex"
        />
        <!-- 切换区域 -->
        <div class="grid-parent">
            <Grid
                v-show="showGrid"
                ref="grid"
                @closeGrid="closeGrid"
                @gridItemClick="gridItemClick"
                @recommendClick="recommendClick"
            />
            <!-- 侧边按钮 -->
            <div
                class="side-btn"
                :style="{bottom:sideBtnTop+'px'}"
                @touchstart="sideBtnTouchStart"
                @touchmove="sideBtnTouchMove"
                @click.stop="openGrid">
                <svg-icon :icon-class='"daolan"+activeGrid.id' className='icon' color="#fff" style="width:60%;height:60%;"></svg-icon>
            </div>

            <!-- 定位按钮 -->
            <div class="pos" @click="getMyLocation">
                <svg-icon :icon-class="'pos'" className='icon' color="#fff" style="width:60%;height:60%;"></svg-icon>
            </div>
        </div>
        <!-- 展示列表区域 -->
        <list-scroll v-show="showListScroll" @closeListScroll="closeListScroll" :activeGridIndex="activeGridIndex" :mapList="mapList" />
        <!-- 地图markers点击弹窗 -->
        <map-modal v-show="showMapModal" @closeMapModal="closeMapModal" :mapItem="mapItem" :activeGridIndex="activeGridIndex" />
        <!-- 路线列表 -->
        <Lines v-if="showLines" :linesList="linesList" :currentLine="currentLine" @changeIndex="changeIndex" @closeClick="closeClick" />
        <Toast :showToast="showToast" @changeToast="changeToast" />
      </div>
      <!-- <div class="mid-div">{{res}}</div> -->
  </div>
</template>

<script>
import Map from '@/components/Map/CustomMap.vue'
import Grid from '@/components/Grid/Grid.vue'
import ListScroll from '@/components/ListScroll/ListScroll.vue'
import MapModal from '@/components/MapModal/MapModal.vue'
import Lines from '@/components/Lines/Lines.vue'
import { getList, getSceneList, getRestList, getRecommendList } from '@/api/index.js'
import { setMapCenter } from '../components/Map/handleTMap'
export default {
    components:{
        Map,
        Grid,
        ListScroll,
        MapModal,
        Lines
    },
    data(){
        return{
            topIndex: 0,
            centerLatLng:{},
            centerLatLng2:{},
            quyus:[
                {
                    id:0,
                    name:"唐山南湖旅游景区",
                    lat:"39.6023074755",
                    lng:"118.1621401495",
                    scale:14,
                    neLat:"39.626180",
                    neLng:"118.196255",
                    swLat:"39.580232",
                    swLng:"118.136957",
                    type: 1
                },
                {
                    id:1,
                    name:"开滦国家矿山公园",
                    lat:"39.622176",
                    lng:"118.198769",
                    scale:16, 
                    neLat:"39.626890",
                    neLng:"118.206142",
                    swLat:"39.614769",
                    swLng:"118.196875",
                    type: 2
                }
            ],
            showGrid: false,
            activeGrid:{
                title: '景点',
                icon: require('@/assets/images/icon-0.svg'),
                activeIcon: require('@/assets/images/active-icon-0.svg'),
                id: 0
            },
            mapList:[],
            showListScroll: false,
            markers:[],
            mapItem: null,
            lines:[],
            linesList:[],
            showLines: false,  //是否展示底部路线列表
            showToast: false,
            sideBtnTop: 157,
            clientHeight: 0,
            activeGridIndex: 0,
            currentLine: 0,  //线路列表显示下标
            location_step: 0,
            res:'',
            isFirst: 0,
            myLat: '',
            myLng: ''
        }
    },
    computed:{
        points(){
            if(this.showLines){
                return this.linesList[this.currentLine]
            }
            return {}
        },
        showMapModal(){
            if(this.mapItem && this.showLines){
                this.showLines = false
            }
            if(this.mapItem) return true;
            return false;
        }
    },
    mounted(){
        // document.addEventListener('visibilitychange', this.handleVisiable)
        this.centerLatLng = this.quyus[0]
        // this.changeList(this.quyus[0])
        this.clientHeight = document.body.scrollHeight || window.screen.height
        console.log(location.hash)
        this.searchParams()
    },
    methods:{
        // 点击我的位置
        getMyLocation(){
            let quyu = this.quyus[this.topIndex]
            let { neLat, neLng, swLat, swLng } = quyu
            if((this.myLat > neLat || this.myLng > neLng) || (this.myLat < swLat || this.myLng < swLng)){
                alert("请在南湖·开滦旅游景区内使用此功能")
                return
            }

            this.markers.push({
                id: 666,
                latitude: this.myLat,
                longitude: this.myLng,
                name :'',
                title: '',
                mapBig: 20,
                mapSmall: 3
            })
            setMapCenter(this.myLng, this.myLat)
        },
        handleVisiable(e){
            if(e.target.visibilityState === 'visible'){
                this.searchParams()
            }
        },
        //根据页面路径参数做不同的操作
        searchParams(){
            let hash = location.hash
            let params = hash.split('&')
            let id,cateId = 0;
            //解析浏览器路径参数
            let o = {}
            params.forEach((item,index)=>{
                let itemArr = item.split("=")
                if(index==0){
                    let i = item.indexOf("?")
                    let item2 = item.slice(i+1)
                    itemArr = item2.split("=")
                }
                o[itemArr[0]] = itemArr[1]
            })
            id = o.line
            cateId = o.cateId
            this.myLat = Number(o.myLat)
            this.myLng = Number(o.myLng)
            console.log("cateId:",cateId,"id:",id)
            if(id){
                //请求线路
                getRecommendList().then(res => {
                    let { list } = res
                    console.log(list)
                    //赋值线路数组
                    this.linesList = list
                    console.log(JSON.parse(list[0].points))
                    //当前线路
                    let line = list.find(item => {
                        return item.id == id
                    })
                    //当前线路下标
                    this.currentLine = list.findIndex(item => {
                        return item.id == id
                    })
                    //判断开滦路线
                    if(id == 7){
                        let kl = this.quyus[1]
                        this.mapTopClick(kl, false)
                    }
                    this.lines = JSON.parse(line.points).paths
                    this.showGrid = false
                    this.showLines = true
                    let myLoc = this.markers.filter(item => item.id == 666)
                    this.markers = myLoc
                })
            }else if(cateId){
                //判断卫生间
                this.activeGridIndex = 3
                let grid = {
                    title: '卫生间',
                    id: 7,
                    iconName: 'icon-7',
                    type: 10
                }
                this.activeGrid = grid
                this.$refs.grid.$data.activeIndex = 7
                this.changeList(grid)
            }else{
                this.changeList(this.quyus[0])
            }
        },
        changeToast(){
            this.showToast = false
        },
        //关闭推荐线路
        closeClick(){
            this.showLines = false
        },
        //切换线路
        changeIndex(type){
            if(type >= 1){
                this.currentLine = this.currentLine < this.linesList.length -1 ? this.currentLine + 1 : this.linesList.length - 1
            }else{
                this.currentLine = this.currentLine <=0 ? 0 : this.currentLine - 1
            }
            let index = this.currentLine
            console.log(index,'++++++')
            let quyus = null
            if(this.linesList[index].id == 7){
                quyus = this.quyus[1]
            }else{
                quyus = this.quyus[0]
            }
            this.topIndex = quyus.id
            this.centerLatLng = this.quyus[quyus.id]
            
            this.lines = JSON.parse(this.linesList[index].points).paths
        },
        //点击推荐线路按钮
        recommendClick(){
            getRecommendList().then(res => {
                let { list } = res
                console.log(list)
                this.linesList = list
                console.log(JSON.parse(list[0].points))
                this.lines = JSON.parse(list[0].points).paths
                this.showGrid = false
                this.showLines = true
                let myLoc = this.markers.filter(item => item.id == 666)
                this.markers = myLoc
            })
        },
        pauseAudio(){
            let audio = document.querySelectorAll('audio')[0]
            console.log(audio)
            if(audio && !audio.paused){
                audio.pause()
                //查找mapModal
                let childs = Array.from(this.$children)
                // console.log(childs)
                let mapModal = childs.find(item => {
                    return item.durationTime
                })
                console.log(mapModal)
                // 重置播放图标
                if(mapModal){
                    mapModal.playing = false
                }
            }
            
        },
        closeMapModal(){
            this.mapItem = null
        },
        markerClick(id){
            console.log("父组件接收marker",id)
            //赋值mapItem
            let mapItem = this.mapList.find(item=>{
                return item.id == id
            })
            this.mapItem = mapItem
            let {latitude, longitude} = mapItem
            this.centerLatLng2 = { lat: latitude, lng: longitude}
            this.pauseAudio()
        },
        //************  渲染地图markers  ***********
        initMap(){
            let myLoc = this.markers.filter(item => item.id == 666)
            this.markers = myLoc
            this.mapList.length > 0 ? this.markers = [...this.mapList] : ''
        },
        // 关闭景点列表弹窗
        closeListScroll(){
            this.showListScroll = false
        },
        //请求景点
        getSceneList(spotType){
            getSceneList({spotType}).then(res => {
                let { list } = res.page
                list.forEach(item => {
                    item.name = item.title
                    item.imgUrl = item.banner
                })
                this.mapList = list
                this.showListScroll = this.isFirst == 1
                this.isFirst = 1
                this.closeGrid()
                this.initMap()
            })
        },
        //请求餐饮或娱乐
        getRestList(){
            getRestList({
                type: this.activeGrid.type
            }).then(res => {
                let { list } = res.page
                //判断开滦景区有没有对应的服务
                if(this.topIndex == 1){
                    console.log("开滦==================")
                    let filterList = list.filter(item => {
                        return item.spotType == 2
                    })
                    console.log(filterList)
                    this.mapList = filterList
                    this.showListScroll = filterList.length > 0
                    this.closeGrid()
                    this.initMap()
                    if(filterList.length == 0){
                        this.showToast = true
                    }
                    return
                }
                console.log("南湖=================")
                list.forEach(item => {
                    item.name = item.title
                })
                this.mapList = list
                this.showListScroll = true
                this.closeGrid()
                this.initMap()
            })
        },
        //切换景点、餐饮···
        changeList(item){
            let type = item.id
            if(type == 0){
                //请求景点
                this.getSceneList(this.quyus[this.topIndex]['type'])
                return
            }
            if(type == 1 || type == 4){
                //请求餐饮或娱乐
                this.getRestList()
                return
            }
            getList({
                type: this.activeGrid.type
            }).then(res => {
                console.log(res)
                let list = res.data
                //判断开滦景区有没有对应的服务
                if(this.topIndex == 1){
                    console.log("开滦==================")
                    let filterList = list.filter(item => {
                        return item.spotType == 2
                    })
                    this.mapList = filterList
                    this.showListScroll = filterList.length > 0
                    this.closeGrid()
                    this.initMap()
                    if(filterList.length == 0){
                        this.showToast = true
                    }
                    return
                }
                console.log("南湖==================")
                this.mapList = list
                this.showListScroll = true
                this.closeGrid()
                this.initMap()
            })
        },
        //GRID点击切换景点、餐饮···
        gridItemClick(item, index){
            this.showLines = false
            this.mapList.splice(0)
            this.activeGrid = item
            this.activeGridIndex = index
            this.showLines = false
            this.changeList(item)
        },
        closeGrid(){
            this.showGrid = false
        },
        //拖动侧边按钮
        sideBtnTouchStart(e){
            // console.log(e)
        },
        //拖动侧边按钮
        sideBtnTouchMove(e){
            // console.log(e)
            let my = e.touches[0]['pageY']
            if(my > this.clientHeight-50 || my <= 50) return
            this.sideBtnTop = this.clientHeight - my - 25
            e.stopPropagation()
        },
        //点击侧边按钮打开Grid
        openGrid(){
            this.pauseAudio()
            this.showGrid = true
            this.showLines = false
            //隐藏景点列表弹窗
            this.showListScroll = false
            //隐藏点击marker展示的景点弹窗
            this.mapItem = null
        },
        //切换南湖、开滦
        mapTopClick(item, showList = true){
            if(this.showLines) return
            if(item.id == this.topIndex) return
            this.topIndex = item.id
            this.centerLatLng = this.quyus[item.id]
            this.showLines = false
            // this.centerLatLng2 = { lat: this.quyus[item.id]['lat'], lng: this.quyus[item.id]['lng']}
            if(!showList) return
            this.changeList(this.activeGrid)
        }
    }
}
</script>

<style scoped lang="scss">
    .container{
        width: 100%;
        height: 100vh;
    }
    

    .map-top{
        width: 100%;
        height: 1.35rem;
        background: #fff;
        color: #666;
        font-size: .4rem;
        .top-btn{
            width: 50%;
            height: 1.35rem;
            text-align: center;
            line-height: 1.35rem;
            position: relative;
        }
        .btn-active{
            color: rgba(255, 87, 51, 1);
            &::after{
                content: '';
                width: 100%;
                height: 4px;
                background: rgba(255, 87, 51, 1);
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
    .map-container{
        height: calc(100vh - 1.35rem);
    }
    .side-btn{
        position: fixed;
        right: 19px;
        bottom:157px;
        width: 1.36rem;
        height: 1.36rem;
        z-index: 1000;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon{
        color: red;
    }
    .pos{
        width: 36px;
        height: 36px;
        background: #fff;
        border-radius: 50%;
        position: fixed;
        bottom: 103px;
        right: 19px;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mid-div{
        width: 100%;
        height: 300px;
        background: #ccc;
        color: #fff;
        font-size: 20px;
        position: fixed;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
</style>