<template>
    <div class="lines">
        <div class="line-top">
            <svg-icon iconClass="arrow-left" class="arrow" @click.stop="changeIndex(0)" />
            <div class="line-title">{{linesList[currentLine]['name']}}</div>
            <svg-icon iconClass="arrow-right" class="arrow" @click.stop="changeIndex(1)" />
        </div>
        <div>{{linesList[currentLine]['lineDetails'].length}}个景点</div>
        <ul class="lines-list">
            <li
                v-for="(item,index) in linesDetail"
                :key="index"
                class="line-item"
            >
                <img :src="fileUrl + item.imgUrl" class="line-img" alt="">
                <p class="line-p">{{item.imgTitle}}</p>
            </li>
        </ul>
        <svg-icon iconClass="x" class="close-icon" @click.stop="closeClick" />
    </div>
</template>

<script>
export default {
    props:{
        linesList:{
            type: Array,
            default: []
        },
        currentLine: {
            type: Number,
            default: 0
        }
    },
    computed:{
        linesDetail(){
            return this.linesList[this.currentLine]['lineDetails']
        }
    },
    data(){
        return {
            fileUrl: 'https://jqadmin.tangshannanhu.com/api/upload/',
        }
    },
    methods:{
        //关闭底部路线列表
        closeClick(){
            this.$emit('closeClick')
        },
        changeIndex(type){
            this.$emit("changeIndex", type)
        }
    }
}
</script>

<style scoped lang="scss">
    .lines{
        width: 100%;
        height: 230px;
        background: #fff;
        position: absolute;
        bottom: 0px;
        left: 0;
        z-index: 1100;
        border-radius: 30px 30px 0 0;
        text-align: center;
        color: rgba(128, 128, 128, 1);
        
        .line-top{
            display: flex;
            align-items: center;
            justify-content: center;
            .line-title{
                margin:15px 40px 6px;
                line-height: 30px;
                font-size: 16px;
                font-weight: bold;
            }
            .arrow{
                width: 13px;
                height: 21px;
            }
        }
    }
    .lines-list{
        width: 100%;
        margin-top: 25px;
        box-sizing: border-box;
        padding: 0 17px;
        overflow-x: scroll;
        white-space: nowrap;
        &::-webkit-scrollbar{
            display: none;
        }
        .line-item{
            display: inline-block;
            margin-right: 14px;
            .line-img{
                display: block;
                width: 80px;
                height: 80px;
                border-radius: 20px;
                margin-bottom: 20px;
            }
            .line-p{
                width: 80px;
                font-size: 12px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: rgba(128, 128, 128, 1);
            }
        }
    }
    .close-icon{
        position: absolute;
        right: 25px;
        top: 20px;
        width: 15px;

    }
</style>