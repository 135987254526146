<template>
  <div class="grid">
      <div class="grid-mask" @click.stop="close"></div>
      <div class="grid-main"
        v-show="!showQuanjing">
          <div
            v-for="(item,index) in gridList"
            :key="index"
            class="grid-item"
            @click.stop="gridItemClick(item,index)"
          >
            <!-- 图片svg -->
            <div
                class="grid-icon-wrapper">
                <svg-icon class="grid-icon" v-if="index == activeIndex" :icon-class="'daolan' + index" />
                <svg-icon class="grid-icon" v-else :icon-class="item.iconName" />
                <!-- <img class="grid-icon" :src="index == activeIndex ? item.activeIcon : item.icon" alt=""> -->
            </div>
            <!-- 文字 -->
            <div
                :class="index == activeIndex ? 'yellow-text' : ''">{{item.title}}</div>
          </div>

          <!-- 底部按钮 -->
          <div
            class="bottom-btn-container">
              <div
                class="bottom-btn bottom-btn-1"
                @click.stop="openQuanjing">
                  <img src="../../assets/images/quanjing.png" alt="">
                  <!-- <el-image src="../../assets/images/quanjing.png" alt=""></el-image> -->
                  <span>导览全景图</span>
              </div>
              <!-- 推荐路线按钮 -->
              <div
                class="bottom-btn bottom-btn-2"
                @click.stop="recommendClick">
                  <img src="../../assets/images/tuijian.png" alt="">
                  <span>精品路线推荐</span>
              </div>
              <!-- <div
                class="bottom-btn recommend"
                @click.stop="recommendClick">
              </div> -->
          </div>
          <!-- 关闭按钮 -->
          <div class="close" @click.stop="close">
              <img src="../../assets/images/close.svg" alt="">
          </div>
      </div>
      <!-- <div class="grid-display" v-show="showQuanjing">
          <img
            src="../../assets/images/quanjing.jpg" alt="">
      </div> -->
      <!-- 关闭全景图按钮 -->
        <!-- <div class="close2" v-show="showQuanjing" @click.stop="showQuanjing = false">
            <div>长按保存全景图</div>
            <img src="../../assets/images/close.svg" alt="">
        </div> -->
  </div>
</template>

<script>
export default {
    data (){
        return {
            gridList:[
                {
                    title: '景点',
                    id: 0,
                    iconName: 'icon-0'
                },
                {
                    title: '餐饮',
                    id: 1,
                    iconName: 'icon-1',
                    type: 1
                },
                {
                    title: '停车',
                    id: 2,
                    iconName: 'icon-2',
                    type: 3
                },
                {
                    title: '自行车',
                    id: 3,
                    iconName: 'icon-3',
                    type: 4
                },
                {
                    title: '娱乐',
                    id: 4,
                    iconName: 'icon-4',
                    type: 3
                },
                {
                    title: '服务',
                    id: 5,
                    iconName: 'icon-5',
                    type: 8
                },
                {
                    title: '出入',
                    id: 6,
                    iconName: 'icon-6',
                    type: 9
                },
                {
                    title: '卫生间',
                    id: 7,
                    iconName: 'icon-7',
                    type: 10
                },
                {
                    title: '游船',
                    id: 8,
                    iconName: 'icon-8',
                    type: 12
                },
                {
                    title: '电瓶车',
                    id: 9,
                    iconName: 'icon-9',
                    type: 13
                }
            ],
            activeIndex: 0,
            showQuanjing: false,
            srcList:[
                require("../../assets/images/quanjing.jpg")
            ]
        }
    },
    watch:{
        // showQuanjing(){
        //     if(this.showQuanjing){
        //         // location.href = 'https://nhdaolan.ebanktown.com:8443/map/bigpic.html'
        //         let ua = navigator.userAgent
        //         if(ua.toLowerCase().indexOf('android')){
        //             //安卓
        //             window.open('./bigpic.html','_blank')
        //         }else{
        //             //IOS
        //             var pic = function(){
        //                 var winhref = window.open('','_blank')
        //                 setTimeout(()=>{
        //                     winhref.location = 'https://nhdaolan.ebanktown.com:8443/map/bigpic.html'
        //                 },10)
        //             }
        //             pic()
        //         }
        //     }
        // }
    },
    methods:{
        openQuanjing(){
            let ua = navigator.userAgent
                if(ua.toLowerCase().indexOf('android')){
                    //安卓
                    window.open('https://nhdaolan.ebanktown.com:8443/map/bigpic.html','_blank')
                }else{
                    //IOS
                    var pic = function(){
                        var winhref = window.open('','_blank')
                        setTimeout(()=>{
                            winhref.location = 'https://nhdaolan.ebanktown.com:8443/map/bigpic.html'
                        },10)
                    }
                    pic()
                }
        },
        recommendClick(){
            this.$emit('recommendClick')
        },
        close(){
            this.$emit('closeGrid')
        },
        //grid点击
        gridItemClick(item,index){
            this.activeIndex = index
            this.$emit('gridItemClick', item, index)
        }
    }
}
</script>

<style scoped lang="scss">
    .grid-mask{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .3);
        z-index: 1000;
    }
    .grid-main{
        width: 83%;
        height: 60%;
        padding: 20px 10px 0;
        background: #fff;
        border-radius: 15px;
        color: #333;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1001;
        text-align: left;
        .grid-item{
            width: 25%;
            height: 1.5rem;
            display: inline-block;
            text-align: center;
            font-size: .3rem;
            margin-bottom: 30px;
            .grid-icon{
                width: .8rem!important;
                height: .8rem!important;
            }
            div{
                padding-top: 10px;
            }
            .yellow-text{
                color: #ff5733;
            }
        }
        .bottom-btn-container{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            .bottom-btn{
                width: 262px;
                border-radius: 6px;
                background: #fff3f0;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #333;
                letter-spacing: 3px;
                margin-bottom: 15px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .bottom-btn-1{
                img{
                    width: 27px;
                    height: 27px;
                    margin-right: 15px;
                }
            }
            .bottom-btn-2{
                img{
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }
            }
            .recommend{
                background: url('../../assets/images/xianlus.png') no-repeat;
                background-size: 100% 100%;
            }
        }
        .close{
            width: .6rem;
            height: .6rem;
            position: absolute;
            bottom: -46px;
            left: 50%;
            transform: translateX(-50%);
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .grid-display{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 1000;
        overflow: scroll;
        img{
            width: 100%;
            height: 60%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        .quanjing-img{
            width: 100vw;
            height: 60vh;
            position: absolute;
            top: 50%;
        }
        .quanjing-img >>> .el-image__inner{
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        
    }
    .close2{
        width: .6rem;
        height: 1.6rem;
        position: fixed;
        bottom: 64px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
        img{
            width: 100%;
            height: 100%;
        }
        div{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            color: #fff;
            white-space: nowrap;
        }
    }
</style>