<template>
    <div class="list-scroll">
        <!-- top最小到25% 最大到50% -->
        <div class="list-wrapper" :style="{top:top+'%'}">
            <!-- 关闭按钮 -->
            <svg-icon icon-class="close" className='close-icon' @click.stop="closeListScroll" />
            <div class="list-main">
                <div class="touch" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
                    <img src="../../assets/images/touch.png" class="touch-icon" alt="">
                </div>
                <div class="scroll">
                    <div class="list-item"
                        v-for="(item,index) in mapList"
                        :key="index"
                    >
                        <div class="item-name">{{item.name}}</div>
                        <img class="item-img" v-if="item.imgUrl" @click="toWxMini(item)" :src="fileUrl + item.imgUrl" alt="">
                        <div class="item-bottom">
                            <!-- <span class="item-address" v-if="item.address">{{item.address}}</span> -->
                            <span><span class="bottom-lt" @click="toWxMini(item)" v-if="activeGridIndex < 2">查看详情</span></span>
                            <span @click="goThere(item)">去这里 ></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        mapList:[],
        activeGridIndex: 0
    },
    data(){
        return {
            fileUrl: 'https://jqadmin.tangshannanhu.com/api/upload/',
            top:15,
            clientHeight: 1920
        }
    },
    mounted(){
        this.clientHeight = document.body.clientHeight || window.screen.height
        console.log('浏览器高度：',this.clientHeight)
    },
    watch:{
        mapList(){
            //当列表内容为空时，重置top值
            if(this.mapList.length == 0){
                this.top = 15
            }
        }
    },
    methods:{
        goThere(item){
            if(item.longitude && item.latitude){
                wx.miniProgram.navigateTo({
                    url: `/pages/guide/daohang?latitude=${item.latitude}&longitude=${item.longitude}&mername=${item.name}`,
                });
            }
        },
        toWxMini(item){
            if(this.activeGridIndex > 1) return
            console.log(item)
            let url = [
                "/pages/piao/goods/details?merid=" + item.id,
                "/pages/meishi/store/details?id=" + item.id
            ]
            console.log(url[this.activeGridIndex])
            wx.miniProgram.navigateTo({
                url: url[this.activeGridIndex],
            });
        },
        closeListScroll(){
            this.$emit('closeListScroll')
        },
        handleTouchStart(e){
            // console.log(e)
        },
        handleTouchMove(e){
            // console.log(e)
            if(e.touches && e.touches.length > 1){
                e.preventDefault()
                return false
            }
            let mh = e.changedTouches[0]['pageY']
            let rate = mh / this.clientHeight
            if(rate <= 0.3 || rate >= 0.75){
                return false;
            }
            this.top = rate * 100
        },
        handleTouchEnd(e){
            // console.log(e)
        }
    }
}
</script>

<style scoped lang="scss">
    .list-scroll{
        width: 100%;
        height: calc(100vh - 1.4rem);
        position: fixed;
        top: 1.4rem;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        overflow: hidden;
        .list-wrapper{
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            width: 100%;
            .list-main{
                background: #fff;
                border-radius: 30px 30px 0 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                padding-bottom: 100px;
                &::-webkit-scrollbar{
                    display: none;
                }
                // max-height: 50vh;
                // overflow-y: scroll;
                .scroll{
                    max-height: 70vh;
                    overflow-y: scroll;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                }
            }
        }
    }
    .list-item{
        padding: 10px 12px;
        border-bottom: 1px solid #ccc;
        color: #202020;
        .item-name{
            padding: 10px 30px;
        }
        .item-name{
            font-weight: bold;
            font-size: .48rem;
            line-height: 40px;
        }
        .item-img{
            height: 5.84rem;
            width: 100%;
            border-radius: 20px;
            object-fit: cover;
        }
        .item-bottom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: .37rem;
            font-weight: bold;
            color: rgba(255, 87, 51, 1);
            margin-top: 10px;
            line-height: 33px;
            .bottom-lt{
                border: 1px solid rgba(255, 87, 51, 1);
                padding: 2px 6px;
                border-radius: 12px;
                font-weight: normal;
            }
        }
        &:last-child{
            padding-bottom: 70px;
        }
    }
    .touch{
        height: 1rem;
        background: #fff;
        // border-radius: 30px 30px 0 0;
        border-radius: .5rem .5rem 0 0;
        // position: relative;
        .touch-icon{
            display: block;
            height: .1rem;
            width: 70px;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            top: 20px;
            transform: translate(-50%,0);
        }
    }
    
    .close-icon{
        width: .5rem!important;
        height: .5rem!important;
        position: absolute;
        top: 10px;
        right: 11px;
        z-index: 1000;
    }
</style>